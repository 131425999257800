<template>
  <b-row class="match-height">
    <b-col lg="6"> <b-card
    title="Settings"
  >
    <b-form
        @submit.prevent="saveSettingsProcess">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Version Check *"
            label-for="version_check"
            label-cols-md="4"
          >
            <b-form-input
              id="version_check"
              placeholder="Version Check"
              required="required"
              v-model="VersionCheck"
            />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>

    </b-form>

  </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard,
  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import moment from 'moment'
import { getToken } from '@/auth/utils'

const userToken = getToken()
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${userToken}`,
}
const VersionCheck = ''
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  data() {
    return {
    VersionCheck,
    }
  },
  mounted() {
      this.settingsCheck()
  },
  methods: {
  settingsCheck() {
    axios
      .get('https://api.geolims.com/invoice_service/InvoiceVersion', { headers })
      .then(response => {
        if (response.data.Status === 1 && response.data.Payload.length > 0) {
          this.modalShow = true
          this.VersionCheck = response.data.Payload[0].invoiceVersionNumber
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Settings Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Settings Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get Settings Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  saveSettingsProcess() {
      const body = {
        invoiceVersionNumber: this.VersionCheck,
      }
    axios
      .post('https://api.geolims.com/invoice_service/changeInvoiceVersion', body, { headers })
      .then(response => {
        if (response.data.Status === 1) {
          this.modalShow = false
          this.InvoiceNumber = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Save Settings Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Save Settings Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Save Settings Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
    dateFormat(value, format = 'MM/DD/YYYY') {
        return moment(String(value)).format(format)
    },
  },
}
</script>
